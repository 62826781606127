<template>
  <div class="container" :class="classModifiers">
    <sp-button class="activator" color="surface">
      <sp-icon
        v-if="prependIcon && !active"
        slot="prepend"
        class="insurance-plan-filter__activator-icon --prepend"
        :name="prependIcon"
      />
      <template v-if="active">
        <div class="activator__label">
          {{ label }}
          <sp-icon class="insurance-plan-filter__activator-icon --append" name="popup-arrow-filled" />
        </div>
        <div class="activator__title" :title="text">{{ text }}</div>
      </template>
      <template v-else>
        <div class="activator__label">{{ label }}</div>
      </template>
      <sp-icon
        v-if="!active"
        slot="append"
        class="insurance-plan-filter__activator-icon --append"
        name="popup-arrow-filled"
      />
    </sp-button>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  active: {
    type: Boolean,
    default: false,
  },
  prependIcon: {
    type: String,
    default: undefined,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const classModifiers = computed(() => ({
  "--is-active": props.active,
  "--is-open": props.open,
}));
</script>

<style scoped lang="scss">
.container {
  --activator-title-font-size: var(--sp-comp-df-filter-title-font-size, 0.9375rem);
  --activator-label-font-size: var(--sp-comp-df-filter-label-font-size, var(--activator-title-font-size));
  --activator-border-color: rgba(0, 112, 120, 0.5);
  --activator-border-radius: var(--sp-comp-df-filter-border-radius, 15px);

  border-radius: var(--activator-border-radius);
  background-color: transparent;
  height: 70px;
  box-sizing: border-box;
  width: max-content;

  .activator {
    --border-width: 1px;
    --border-style: dashed;
    --hover-border-color: var(--activator-border-color);
    --border-color: var(--activator-border-color);
    --button-border-radius: var(--activator-border-radius);
    --button-justify-content: center;
    --button-min-height: 70px;
    --button-content-wrap: nowrap;
    --button-content-text-align: left;
    --button-content-text-overflow: ellipsis;
    --button-gap: 0.25ch;
    --bg-color: transparent;
    --activator-label-color: var(--sp-sys-color-primary-dark, #00818a);
    --button-min-width: 3rem;
  }

  .activator__title {
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20ch;
    font-size: var(--activator-title-font-size);
    font-family: var(--activator-title-font-family, var(--sp-sys-font-family-normal));
    line-height: 1.3;
  }

  .activator__label {
    display: flex;
    align-items: center;
    font-family: var(--activator-label-font-family, var(--sp-sys-font-family-medium));
    font-size: var(--activator-label-font-size);
    color: var(--activator-label-color);
    line-height: 1.1;
  }

  .insurance-plan-filter__activator-icon {
    --fill: var(--activator-label-color);
    --size: 20px;
  }

  &.--is-active {
    --filter-bar-close-action-display: block;
    --activator-label-font-size: var(--sp-comp-df-filter-active-label-font-size, 0.813rem);
    --activator-border-color: white;
    --sp-ce-button-focus-opacity: 0;
    background-color: white;
    box-shadow:
      0px 1px 6px 0px rgba(0, 0, 0, 0.25),
      0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    .insurance-plan-filter__activator-icon {
      &.--append {
        --size: 14px;
        margin-top: -1px;
        margin-left: 4px;
      }
    }
  }
  &.--is-open {
    outline: 4px solid var(--sp-sys-color-primary);
    --activator-border-color: transparent;
    --sp-ce-button-focus-opacity: 0;
  }
}
</style>
