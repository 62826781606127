<template>
  <sp-menu
    ref="root"
    class="distance-filter"
    :open="menuOpen"
    :close-on-content-click="closeOnContentClick"
    pointer
    @update-open="handleUpdateOpen"
  >
    <div slot="activator">
      <slot name="activator" :label="label" :text="activatorTitle" :prepend-icon="prependIcon" :open="open">
        <DefaultFilterActivator
          :value="activatorTitle"
          :label="label"
          :prepend-icon="prependIcon"
          no-divider
          class="distance-filter__activator"
          @clear="clearSelectedModel"
        />
      </slot>
    </div>

    <sp-card>
      <sp-card-content>
        <sp-select
          prompt="Any Distance"
          label="Distance in Miles"
          :prepend-selected-with="selectedDistancePrependText"
          :items.prop="distanceOptions"
          :value.prop="selectedDistance"
          @input="handleDistanceInput"
        ></sp-select>

        <sp-google-maps-auto-complete
          label="From"
          :search-query="searchQuery"
          @update-model-value="handleGoogleMapsResultChange"
        ></sp-google-maps-auto-complete>
      </sp-card-content>

      <sp-fieldset class="distance-filter__distance-list">
        <sp-quantum-select
          clearable
          no-filter
          no-menu
          hide-title-bar
          hide-search-bar
          mobile-expand-height-max
          listen-on-key-event
          item-title="name"
          :items.prop="locations"
          :loading="loading"
          :error="error"
          :pointer="!hidePointer"
          :selected="[selectedLocation?.value]"
          @update-selected="handleUpdateSelectedLocation"
        >
        </sp-quantum-select>
      </sp-fieldset>
    </sp-card>
  </sp-menu>
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";
import { useForm } from "../../../composables/form";
import { toBoolean } from "../../../utils/props";
import { useDistanceFilter } from "../composables/distance-filter";
import DefaultFilterActivator from "./DefaultFilterActivator.ce.vue";

const root = ref(null);
const { syncFormData } = useForm(root);

const emit = defineEmits(["update:open", "change", "update:model-value"]);

const props = defineProps({
  modelValue: {
    type: Object,
    default: undefined,
  },
  lat: {
    type: [Number, String],
    default: undefined,
  },
  lng: {
    type: [Number, String],
    default: undefined,
  },
  distances: {
    type: Array,
    default: () => [5, 10, 15, 20, 25],
  },
  distance: {
    type: [Number, String],
    default: undefined,
  },
  near: {
    type: String,
    default: undefined,
  },
  /**
   * The form element name of the component.
   */
  name: {
    type: String,
    default: "distance",
  },
  /**
   * The label of the activator.
   *
   * @type {String}
   */
  label: {
    type: String,
    default: "Distance",
  },
  url: {
    type: String,
    default: undefined,
  },
  /**
   * The icon to prepend to the activator.
   *
   * @type {String}
   */
  prependIcon: {
    type: String,
    default: "map-pin-filled",
  },
  hidePointer: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  location: {
    type: String,
    default: undefined,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
  width: {
    type: String,
    default: undefined,
  },
  closeOnSelect: {
    type: [Boolean, String],
    default: false,
  },
  closeOnContentClick: {
    type: [Boolean, String],
    default: false,
  },
  currentLocationLabel: {
    type: String,
    default: "Use my current location",
  },
  currentLocationDisplayText: {
    type: String,
    default: "Current Location",
  },
  currentLocationRetrievingLabel: {
    type: String,
    default: "Retrieving current location",
  },
  currentLocationRetrievalErrorText: {
    type: String,
    default: `An error occurred when trying to detect your current location.
      Please search for your location instead.`,
  },
});

defineExpose({ props });

const distanceFilter = useDistanceFilter(props);
const {
  displayText,
  distanceOptions,
  selectedDistancePrependText,
  error,
  model,
  searchQuery,
  loading,
  locations,
  selectedDistance,
  selectedLocation,
  immediateEmit,
} = distanceFilter;

const menuOpen = ref(toBoolean(props.open));

watch(
  () => props.open,
  (value) => (menuOpen.value = toBoolean(value)),
);
watch(menuOpen, (value) => emit("update:open", value));

/**
 * Props model value is generally ignored, it acts as a compatibility layer for the filter-bar.
 * When a user clears the filter from the filter-bar, the model value is set to `undefined`. which should clear the
 * distance filter.
 */
watch(
  () => props.modelValue,
  (value) => {
    if (!value) {
      distanceFilter.clear();
    }
  },
);

watchImmediate(model, (value) => emit("update:model-value", value));

syncFormData(model, { immediate: immediateEmit.value });

onMounted(async () => {
  await distanceFilter.initialize();
});

function handleUpdateSelectedLocation({ detail }) {
  const [selected] = detail;
  const [location] = selected;
  distanceFilter.updateSelectedLocation(location);
}

function handleUpdateOpen({ detail }) {
  const [isOpen] = detail;
  menuOpen.value = isOpen;
}

function handleDistanceInput({ detail }) {
  const [value] = detail;
  selectedDistance.value = value;
}

function handleGoogleMapsResultChange({ detail }) {
  const [result] = detail;
  distanceFilter.updateGoogleResult(result);
}

// Close the menu when a location is selected
watch(() => [selectedLocation.value], conditionallyCloseMenu);

function conditionallyCloseMenu() {
  if (toBoolean(props.closeOnSelect)) {
    menuOpen.value = false;
  }
}

const activatorTitle = computed(() => displayText.value);

function clearSelectedModel() {
  distanceFilter.clear();
  emit("change", undefined);
}
</script>

<style scoped lang="scss">
@import "../../../../../../assets/stylesheets/mixins/breakpoint";

.distance-filter {
  --sp-comp-popup-min-height: auto;
}

sp-google-maps-auto-complete {
  margin-top: calc(var(--sp-ref-spacing-6) * 2);
}

sp-card-content {
  padding-top: var(--sp-ref-spacing-6);
}

.distance-filter__activator {
  @include sp-breakpoint(sm) {
    --activator-border-width: 0;
  }
}
</style>
